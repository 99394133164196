





























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  H3Input, H3Button, H3Modal, H3Select, H3Icon, H3Form,
} from '@authine/awesome-ui';
import { namespace } from 'vuex-class';
import { RsaEncrypt } from '@WorkPlatform/common/rsg';
import { LoginService, ModifyPasswordService, EnterpriseService } from '@WorkPlatform/api';
import { getLogoutPageConfig } from '@WorkPlatform/common/logoutConfig';
import ChangePassword from './change-password-dialog.vue';
import { parseQuery, removeCookie, setCookie } from '@WorkPlatform/common/utils';

const Global = namespace('Global');

interface LoginData {
  username: string;
  password: string;
  ruleToken?: string;
  passRule?: string;
  baseUserId?: string;
  tenantCode?: string;
}

let submitTimeoutId: any = null;

@Component({
  name: 'AccountLogin',
  components: {
    AForm: H3Form,
    AFormItem: H3Form.Item,
    AInput: H3Input,
    AModal: H3Modal,
    ASelect: H3Select,
    ASelectOption: H3Select.Option,
    AInputPassword: H3Input.Password,
    AButton: H3Button,
    ChangePassword,
    AIcon: H3Icon,
  },
})
export default class AccountLogin extends Vue {
  @Global.Action('getAllPerms') getAllPerms!: any;

  loginData: LoginData = {
    username: '',
    password: '',
    tenantCode: '',
  };

  hideTenantCodeLessLoginPage = true;

  params: any = {};

  selectedTenantCode: string = '';

  index: string = '';

  tenant: any[] = [];

  showSelectTenant: boolean = false;

  submitTime: number = 0;

  submitting: boolean = false;

  isTriggerShake: boolean = false;

  isShowModifyPassword: boolean = false;

  resLoginData: any = {};

  showLoginForm: boolean = true;

  loginError: boolean = false;

  // 判断是否需要重定向
  get isRedirect () {
    return window.location.href.includes('?redirect_url=');
  }

  async getSystemConfig () {
    const { success, data } = await LoginService.getLevelPerms();
    if (success) {
      this.hideTenantCodeLessLoginPage = data.hideTenantCodeLessLoginPage;
    }
  }

  loginForm: any = '';

  async created () {
    await this.getSystemConfig();
    this.showLoginForm = true;
  }

  inputForm () {
    this.loginError = false;
  }

  mounted () {
    this.loginForm = this.$form.createForm(this, { name: 'loginForm' });
  }

  cancelSelectTenant () {
    this.showSelectTenant = false;
  }

  handleSelectTenant (tenantCode: string) {
    this.onSelectTenant(tenantCode);
  }

  async submit (e: any) {
    e.preventDefault();
    this.submitting = true;
    this.loginForm.validateFields(async (err, values) => {
      if (err) {
        this.submitting = false;
        // this.isSubmitAble = true;
        // this.$message.error('请输入账号和密码');
        return;
      }
      const { username, password } = values;
      try {
        const resKey: any = await LoginService.getKey();

        if (!resKey.success) return;

        const dataKey: any = resKey.data;

        const { index, key } = dataKey;

        this.index = index;

        const rsaPassword: any = RsaEncrypt(password, key);
        this.loginData.username = username.replace(/(^\s*)|(\s*$)/g, '');
        this.loginData.password = password;
        // rsa加密结束
        this.params = {
          username: username.replace(/(^\s*)|(\s*$)/g, ''),
          password: rsaPassword,
          client_id: 'api',
          index,
        };
        this.login();
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (e) {
        console.error('🚀 ~ AccountLogin ~ this.loginForm.validateFields ~ e:', e);
        this.submitting = false;
        const getErrorStatus = (error: any) =>
          error.httpStatus || (error.response && error.response.status);
        const status: any = getErrorStatus(e);

        const wait: number = 1000;

        if (status === 403) {
          this.loginError = true;

          this.submitTime = this.submitTime + 1;

          if (this.submitTime > 2) {
            this.isTriggerShake = true;
          }

          clearTimeout(submitTimeoutId);

          submitTimeoutId = setTimeout(() => {
            this.isTriggerShake = false;
            clearTimeout(submitTimeoutId);
          }, wait);
        }
      }
    });
  }

  async login () {
    const { data: resLogin }: any = await LoginService.login(this.params);

    if (!resLogin.success || !resLogin.data) {
      this.submitting = false;
      if (resLogin.errCode === '600010') {
        this.loginError = true;
      } else {
        this.$message.error(resLogin.errMessage);
      }
      return;
    }
    localStorage.setItem('LoggedIn', 'true');

    this.resLoginData = resLogin.data;
    if (resLogin.data.specification) {
      this.submitting = false;
      this.loginData.ruleToken = (resLogin.data as any).access_token;
      const resRule = await ModifyPasswordService.getPasswordRuleInfo(this.loginData.ruleToken);

      if (resRule.success) {
        this.isShowModifyPassword = true;
        this.loginData.passRule = resRule.data;
        this.loginData.baseUserId = resLogin.data.tenants[0]?.userId;
        this.loginData.tenantCode = resLogin.data.tenants[0]?.tenantCode;
        return;
      }
    }
    this.setTenant(resLogin.data.tenants);
  }

  setTenant (tenants: any[]) {
    this.tenant = tenants;
    if (this.tenant.length > 1) {
      const url = this.$route.query.redirect_url as string;
      const query = parseQuery(url);
      const tenantCode = query.tenantCode || this.$route.query.tenantCode;
      if (
        tenantCode &&
        tenants.find(item => item.tenantCode === tenantCode)
      ) {
        this.onSelectTenant(tenantCode);
        return;
      }
      this.showLoginForm = false;
      this.showSelectTenant = true;
    } else {
      this.onSelectTenant(this.tenant[0].tenantCode);
    }
  }

  async onSelectTenant (tenantCode: string) {
    this.selectedTenantCode = tenantCode;
    localStorage.setItem('tenants', JSON.stringify(this.tenant));
    localStorage.setItem('tenantCode', this.selectedTenantCode);
    sessionStorage.setItem('tenantCode', this.selectedTenantCode);
    setCookie('tenantCode', tenantCode);
    window.onfocus = () => {
      const code = sessionStorage.getItem('tenantCode') || '';
      setCookie('tenantCode', code);
    };
    removeCookie('access_token');
    await this.setRefreshTokenSwitch();
    this.setTokenToLocal(this.resLoginData);
    await this.getLoginInfo();
    await this.getAllPerms();
    const address: string = await getLogoutPageConfig();
    await this.getHomePage();
    if (address !== '') {
      localStorage.setItem('logoutPage', address);
    }
    this.submitting = false;
    this.showSelectTenant = false;
    this.goPage();
  }

  async getHomePage () {
    localStorage.removeItem('homePage');
    const { success, data } = await LoginService.getHomePage();
    if (success) {
      const customHomePageHref = data.find((item: any) => {
        return item.propertyCode === 'homePageHref';
      });
      const homePageType = data.find((item: any) => {
        return item.propertyCode === 'homePageType';
      });
      if (customHomePageHref?.propertyValue && homePageType?.propertyValue === 'custom') {
        localStorage.setItem('homePage', customHomePageHref.propertyValue);
      }
    }
  }

  setInfoToLocal (data: any) {
    enum UnitType {
      Dept = 1,
      Role,
      User,
    }

    const user: any = {
      userInfo: { id: data?.id, name: data?.name, username: data?.username, type: UnitType.User },
      departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: UnitType.Dept },
    };
    const userInfo: any = {
      name: data?.name || '-',
      username: data?.username || '-',
      mobile: data?.mobile || '-',
      userId: data.id,
      imgUrl: data?.imgUrl,
    };
    const assetSSO = {
      username: userInfo.username || '-',
      name: userInfo.name || '-',
      mobile: userInfo.mobile || '-',
      client_id: 'api',
      index: this.index,
    };
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('assetSSO', JSON.stringify(assetSSO));
  };

  async getLoginInfo () {
    LoginService.getLoginInfo().then((res: any) => {
      if (res.success) {
        this.setInfoToLocal(res.data);
      }
    });
  }

  setTokenToLocal (data: any) {
    const expireInfo = {
      expired: data.expiration,
      refresh_token: data.refresh_token,
    };
    const token = (data as any).access_token;

    localStorage.setItem('expireInfo', JSON.stringify(expireInfo));
    localStorage.setItem('token', token);
    // 登录后，设置cookie
    const expires = new Date(data.expiration);
    setCookie('access_token', token, {
      expires,
      path: '/',
    });
  }

  /**
   * 设置刷新token开关
   */
  async setRefreshTokenSwitch () {
    const { data } = await LoginService.getRefreshTokenSwitch();
    const key = 'refreshTokenSwitch';
    if (data) {
      localStorage.setItem(key, String(true));
    } else {
      localStorage.removeItem(key);
    }
  }

  hasPermission (code: string) {
    const perm: any = JSON.parse(localStorage.getItem('perm') || '{}');
    return perm.permList.some((item: any) => {
      return item.code.includes(code);
    });
  }

  async goPage () {
    const isInitEnterprise: boolean = await this.checkInitEnterprise();

    if (!isInitEnterprise) {
      return;
    }

    if (this.isRedirect) {
      this.goRedirectPage();
    } else {
      this.goPlatformHome();
    }
  }

  goRedirectPage () {
    let redirectUrl: string = this.$route.query.redirect_url as string;

    if (
      (redirectUrl.includes('/app_studio') && !this.hasPermission('development')) ||
      (redirectUrl.includes('/management') && !this.hasPermission('management'))
    ) {
      this.goPlatformHome();
      return;
    }

    const query = parseQuery(redirectUrl);
    if (query.tenantCode) {
      redirectUrl = redirectUrl.replace(`tenantCode=${query.tenantCode}`, `tenantCode=${sessionStorage.getItem('tenantCode')}`);
    }

    window.location.href = redirectUrl.replace('hashsymbol', '#');
  }

  async checkInitEnterprise () {
    const { success, data } = await EnterpriseService.checkEnterpriseExist();
    if (!success) return false;
    if (!data || !data?.id) {
      this.goSetEnterprise();
      return false;
    } else {
      return true;
    }
  }

  /**
   * 跳到工作台
   */
  goPlatformHome () {
    const homePage = localStorage.getItem('homePage');
    if (homePage) {
      window.location.href = homePage;
    } else {
      this.$router.push('/work-platform-home').catch(() => { });
    }
  }

  goSetEnterprise () {
    this.$router.push('/set-enterprise');
  }

  closeIt () {
    this.isShowModifyPassword = false;
  }
}
