








import { Component, Vue } from 'vue-property-decorator';
import AccountLogin from './account-login.vue';
import { removeCookie } from '@WorkPlatform/common/utils';

@Component({
  name: 'Login',
  components: {
    AccountLogin,
  },
})
export default class Login extends Vue {
  beforeCreate () {
    removeCookie('pageCode');
    removeCookie('type');
    removeCookie('id');
    removeCookie('code');
  }
}
