















































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { ModifyPasswordService, LoginService } from '@WorkPlatform/api';
import { RsaEncrypt } from '@WorkPlatform/common/rsg';
import * as Utils from '@WorkPlatform/common/utils';
import { H3FormModel, H3Input, H3Modal } from '@authine/awesome-ui';
@Component({
  name: 'ChangePassword',
  components: {
    AFormModel: H3FormModel,
    AFormModelItem: H3FormModel.Item,
    AInputPassword: H3Input.Password,
    AModal: H3Modal,

  },
})
export default class ModifyPassword extends Vue {
  @Prop() visible!: boolean;

  @Prop() data!: any;

  form: any = {
    newPassword: '',
    comfirmPassword: '',
  };

  tip: any = '';

  typeOptions: any[] = [
    {
      label: '数字',
      value: 'digit',
    },
    {
      label: '小写字母',
      value: 'lowercaseLetter',
    },
    {
      label: '大写字母',
      value: 'uppercaseLetter',
    },
    {
      label: '英文特殊字符（除空格）',
      value: 'specialCharacter',
    },
  ];

  rules: any = {
    newPassword: [{
      pattern: /^[^\s]*$/,
      message: '请不要输入空格',
    }, {
      max: 32,
      message: '长度不能超过32位',
      trigger: 'blur',
    }, { validator: this.newPasswordValid, trigger: 'blur' }],
    comfirmPassword: [
      { validator: this.comfirmPasswordValid, trigger: 'blur' },
    ],
  };

  @Watch('visible')
  visibleChange (v: boolean) {
    if (v) {
      this.tip = this.getPassworTip();
    } else {
      this.form = {
        newPassword: '',
        comfirmPassword: '',
      };
    }
  }

  @Watch('form', { deep: true })
  onFormChange () {
    Utils.trim(this.form);
  }

  getPassworTip () {
    let str: any = '';
    this.typeOptions.forEach((item) => {
      if (this.data.passRule[item.value]) {
        if (str.length === 0) {
          str += item.label;
        } else {
          str += '、' + item.label;
        }
      }
    });
    return `至少包含${str}，最少${this.data.passRule.passwordMinLength}位，最高${this.data.passRule.passwordMaxLength}位`;
  }

  newPasswordValid (rule: any, value: any, callback: any) {
    const reg = new RegExp(this.data.passRule.regularExpression, 'ig');
    if (!value) {
      callback(new Error('密码不能为空！'));
    } else if (!reg.test(value)) {
      callback(new Error(this.getPassworTip()));
    } else {
      callback();
    }
  }

  comfirmPasswordValid (rule: any, value: any, callback: any) {
    if (!value) {
      callback(new Error('密码不能为空！'));
    } else if (value !== this.form.newPassword) {
      callback(new Error('两次密码输入不一致，请重新输入!'));
    } else {
      callback();
    }
  }

  handleOk () {
    (this.$refs.form as any).validate((valid: any) => {
      if (valid) {
        this.requestService();
      }
    });
  }

  evil (fn) {
    // 本质是eval()，防止eslint报错
    const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
    return new Fn('return ' + fn)();
  }

  cancel () {
    this.$emit('close');
  }

  async requestService () {
    const { data } = await LoginService.getKey();

    var { index, key } = data;

    const newPassword: any = RsaEncrypt(this.form.newPassword, key);
    const oldPassword: any = RsaEncrypt(this.data.password, key);
    const params: any = {
      oldPassword,
      newPassword,
      index,
    };

    const { success } = await ModifyPasswordService.modifyPassword(params, this.data.baseUserId, this.data.tenantCode, this.data.ruleToken);
    if (success) {
      this.$message.success('设置成功！');
      this.$emit('close');
      Utils.removeStorage();
    } else {
      this.$message.warning('设置新密码失败！');
    }
  }
}
